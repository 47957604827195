import Vue from "vue";
import DatePicker from "@/components/Inventory/DatePicker.vue";
// import Select from "@/components/General/Select.vue";
// import Checkbox from "@/components/General/Checkbox.vue";
import TextField from "@/components/General/TextField.vue";
import vBroker from "@/directives/v-broker";
Vue.component("date-picker", DatePicker);
// Vue.component("select-component", Select);
// Vue.component("checkbox", Checkbox);
Vue.component("text-field", TextField);

Vue.mixin({
  methods: {
    showSnackbar(color, text) {
      this.$store.commit("showSnackbar", {
        show: true,
        color: color,
        text: text,
      });
    },
    hideSnackbar(index) {
      this.$store.commit("hideSnackbar", index);
    },
  },
});

Vue.directive("broker", vBroker);
// (el, binding, vnode) => {
//   console.log("sssdec");
//   console.log(vBroker.broker);
//   // vBroker(el, binding, vnode);
// });

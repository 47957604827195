import { localize, validate, extend } from "vee-validate";
import {} from "vee-validate";
import { fromISOToUtc } from "@/helpers/mocks/dates.js";
import {
  required,
  email,
  min,
  max,
  alpha,
  numeric,
  alpha_num,
  alpha_spaces,
  double,
} from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("numeric", numeric);
extend("alpha", alpha);
extend("alpha_spaces", alpha_spaces);

extend("alpha_num", alpha_num);
extend("double", double);
extend("alpha_numeric_spaces", {
  validate: (value) => {
    const pattern = /^[a-zA-Z0-9 ]*$/;

    return pattern.test(value);
  },
});
extend("max_num", {
  validate: (value, arg) => {
    return Number(value) <= Number(arg[0]);
  },
});

extend("min_num", {
  validate: (value, arg) => {
    return Number(value) >= Number(arg[0]);
  },
});

extend("not_space", {
  validate: (value) => {
    const pattern = /^\S+$/;

    return pattern.test(value);
  },
});
extend("to_date", {
  validate: (value, arg) => {
    const [from_date] = arg;
    if (from_date) {
      return value >= from_date;
    }
    return true;
  },
});
extend("min_date", {
  validate: (value, arg) => {
    const value_parsed = Math.trunc(Date.parse(fromISOToUtc(value)));

    return value_parsed >= arg[0];
  },
});
localize({
  en: {
    messages: {
      required: (field) => `${field} is required`,
      email: (field) => `${field} is invalid`,
      min: (field, { length }) =>
        `${field} must have no less than ${length} characters`,
      max: (field, { length }) =>
        `${field} must have no more than ${length} characters`,

      alpha: (field) => `${field} must have alphabetic characters only`,
      alpha_spaces: (field) => `${field} must have alphabetic characters only`,

      numeric: (field) => `${field} must have numeric characters only`,
      alpha_num: (field) => `${field} must have alpha numeric characters only`,
      alpha_numeric_spaces: (field) =>
        `${field} must have alpha numeric characters only`,
      max_num: (field, values) =>
        `${field} amount must not be more than ${values[0]}`,
      not_space: (field) => `${field} must not have spaces`,
      to_date: (field) => `${field} must have to be greater than Date From`,
      min_num: (field, values) =>
        `${field} amount must not be less than ${values[0]}`,
    },
  },
});

import {
  get_report_end_of_month,
  get_report_end_of_month_2,
  get_report_massrebill,
  get_report_salepending_2,
  get_report_salepending_gb,
  download_report,
  get_repott_general,
  download_bi_weekly_report,
  upload_mass_rebill,
  get_release_issues,
} from "@/helpers/api/Reports/index.js";

export default {
  namespaced: true,
  state: {
    items: [],
    fileName: "",
    fileURL: "",
    errorMessage: "",
    searchValue: "",
    labels: [],
    filteredData: [],
    loading: false,
    releaseIssuesContainer: [],
  },
  mutations: {
    setItems(state, newState) {
      state.items = newState;
    },
    setReleaseIssuesContainer(state, newState) {
      state.releaseIssuesContainer = newState;
    },
    setFileName(state, newState) {
      state.fileName = newState;
    },
    setFileURL(state, newState) {
      state.fileURL = newState;
    },
    setErrorMessage(state, newState) {
      state.errorMessage = newState;
    },
    setSearchValue(state, newState) {
      state.searchValue = newState;
    },
    setLabels(state, newState) {
      state.labels = newState;
    },
    setFilteredData(state, newState) {
      state.filteredData = newState;
    },
    setLoading(state, newState) {
      state.loading = newState;
    },
  },
  actions: {
    async getReportEndOfMonth({ commit }, data) {
      commit("setLoading", true);
      let response;
      try {
        response = await get_report_end_of_month_2(data);

        commit("setItems", response.data);
        commit("setFileName", response.file);

        commit(
          "setSnackbar",
          {
            text: "Got Report end of month",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `${error.response.data.error.error}`,
            status: "warning",
            show: "showNav",
          },
          { root: true }
        );
        commit("setErrorMessage", error.response.data.error.error);
      }
      commit("setLoading", false);

      return response;
    },
    async getReportMassRebill({ commit }, data) {
      commit("setLoading", true);

      let response;
      try {
        response = await get_report_massrebill(data);

        commit("setItems", response.data);
        commit("setFileName", response.file);

        commit(
          "setSnackbar",
          {
            text: "Got Report Mass Rebill",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `${error.response.data.error}`,
            status: "warning",
            show: "showNav",
          },
          { root: true }
        );
        commit("setErrorMessage", error.response.data.error);

        console.log(error.response);
      }
      commit("setLoading", false);

      // commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getReportSalePending({ commit }, data) {
      // commit("setSpinnerShow", true, { root: true });
      commit("setLoading", true);

      let response;
      try {
        response = await get_report_salepending_2(data);

        commit("setItems", response.data);
        commit("setFileName", response.file);

        commit(
          "setSnackbar",
          {
            text: "Got Report Sale Pending",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            // text: `Something went wrong.${error.response.data.error.error}`,
            text: `${error.response.data.error.error}`,
            status: "warning",
            show: "showNav",
          },
          { root: true }
        );
        commit("setErrorMessage", error.response.data.error.error);
        console.log(error.response);
      }
      commit("setLoading", false);

      // commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getReportSalePendingGB({ commit }, data) {
      // commit("setSpinnerShow", true, { root: true });
      commit("setLoading", true);
      console.log("heres", data);
      let response;
      try {
        response = await get_report_salepending_gb(data);

        commit("setItems", response.data.data);
        commit("setFileName", response.file);

        commit(
          "setSnackbar",
          {
            text: "Got Report Sale Pending",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            // text: `Something went wrong.${error.response.data.error.error}`,
            text: `${error.response.data.error.error}`,
            status: "warning",
            show: "showNav",
          },
          { root: true }
        );
        commit("setErrorMessage", error.response.data.error.error);
        console.log(error.response);
      }
      commit("setLoading", false);

      // commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async downloadReportEndOfMonth({ state, commit }) {
      // commit("setSpinnerShow", true, { root: true });
      commit("setLoading", true);

      let response;
      try {
        response = await download_report({
          name: state.fileName,
        });

        commit("setFileURL", response.url);

        commit(
          "setSnackbar",
          {
            text: "Report downloaded",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setLoading", false);

      // commit("setSpinnerShow", false, { root: true });
      return;
    },
    async report({ state, commit }) {
      // commit("setSpinnerShow", true, { root: true });
      commit("setLoading", true);

      let response;
      try {
        response = await get_repott_general();
        commit("setItems", response.data);
        commit("setFileName", response.file);
        commit(
          "setSnackbar",
          {
            text: "Report Got",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setLoading", false);

      // commit("setSpinnerShow", false, { root: true });
      return;
    },
    async reportReleaseIssues({ state, commit }) {
      // commit("setSpinnerShow", true, { root: true });
      commit("setLoading", true);

      let response;
      try {
        response = await get_release_issues();

        const dataPayments = response.data.data.PaymentData.map((item) => ({
          ...item,
          Release_Ref: item.Release_Ref.toUpperCase(),
        }));
        commit("setItems", dataPayments);

        const payments = dataPayments.reduce((acc, item) => {
          const releaseValue = item.Release_Ref.toUpperCase();
          acc[releaseValue] = acc[releaseValue]
            ? {
                price: item.Price
                  ? `${acc[releaseValue].price}; ${item.Price}`
                  : `${acc[releaseValue].price}`,
                transaction: item.Transaction_number
                  ? `${acc[releaseValue].transaction}; 
                ${item.Transaction_number}`
                  : `${acc[releaseValue].transaction}`,
              }
            : {
                price: item.Price ? `${item.Price}` : "",
                transaction: item.Transaction_number
                  ? `${item.Transaction_number}`
                  : "",
              };

          return acc;
        }, {});

        const containers = response.data.data.ContainersData.reduce(
          (acc, item) => {
            const releaseValue = item.Release_Ref.toUpperCase();

            item.price = payments[releaseValue].price;
            item.transaction = payments[releaseValue].transaction;

            item.is_on_eoo = item.iic_container ? "Y" : "N";
            acc[releaseValue] = acc[releaseValue]
              ? [...acc[releaseValue], item]
              : [item];

            return acc;
          },
          {}
        );
        commit("setReleaseIssuesContainer", containers);

        commit(
          "setSnackbar",
          {
            text: "Report Got",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setLoading", false);

      // commit("setSpinnerShow", false, { root: true });
      return;
    },
    async downloadBiWeeklyReport({ commit }) {
      let res;
      commit("setSpinnerShow", true, { root: true });
      try {
        res = await download_bi_weekly_report();
        commit(
          "setSnackbar",
          {
            text: "Report Downloaded",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res.data;
    },
    async uploadMassRebill({ commit }, file) {
      let res;
      commit("setSpinnerShow", true, { root: true });
      try {
        res = await upload_mass_rebill(file);

        commit(
          "setSnackbar",
          {
            text: res.data.send.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return res.data;
    },
  },
};

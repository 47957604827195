export const search = ({ searchInput, header, arr }) => {
  if (!searchInput || !searchInput.length) return arr;

  let headerValues = header.map((header) => header.value);
  return arr.filter(
    (value) =>
      !!Object.keys(value).filter(
        (key) =>
          value[key] &&
          headerValues.includes(key) &&
          value[key]
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()),
      ).length,
  );
};

export const searchMultiFilter = ({ searchInputs, key, items }) => {
  if (searchInputs.length === 0) return items;
  searchInputs = searchInputs.map((input) => input.toString().toLowerCase());
  const filteredItems = items.filter((item) => {
    if (
      item[key] &&
      searchInputs.includes(item[key].toString().toLowerCase())
    ) {
      return item;
    }
  });
  return filteredItems;
};

export const searchByOperator = ({ searchValue, operator, key, items }) => {
  if (!searchValue || !searchValue.length) return items;
  console.log(!searchValue || !searchValue.length, "s");
  const isDate = searchValue.includes("/");
  const isNumber = parseFloat(searchValue);
  const value = isDate
    ? new Date(searchValue)
    : isNumber
      ? parseFloat(searchValue)
      : searchValue;

  const filteredItems = items.filter((item) => {
    const itemValue = isDate
      ? new Date(item[key])
      : isNumber
        ? parseFloat(item[key])
        : item[key];

    if (operator === ">" && itemValue) {
      if (itemValue > value) return item;
    }
    if (operator === "<" && itemValue) {
      if (itemValue < value) return item;
    }
    if (operator === ">=" && itemValue) {
      if (itemValue >= value) return item;
    }
    if (operator === "<=" && itemValue) {
      if (itemValue <= value) return item;
    }
    if (operator === "=" && itemValue) {
      if (itemValue == value) return item;
    }
    if (operator === "!=" && itemValue) {
      if (itemValue != value) return item;
    }
    return null;
  });
  return filteredItems;
};
